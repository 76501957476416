@import "../../../assets/scss/variables";

.Button {
  background-color: transparent;
  padding: 0 0.5rem;
  border-radius: 0.65rem;
  display: block;
  cursor: pointer;
  color: $white-color;
  text-decoration: none;
  // transition: all 200ms ease !important;

  p {
    font-size: 1.5rem !important;
    margin-bottom: 0;
  }
}

.primary {
  border: 1px solid $white-color;

  &:hover {
    background-color: $white-color;
    color: $primary-color;
  }
}

.secondary {
  border: 1px solid $primary-color;
  background-color: $primary-color;

  &:hover {
    background-color: $white-color;
    color: $primary-color;
  }
}

.Hide {
  // visibility: hidden;
  opacity: 0 !important;
  pointer-events: none !important;
}

@media (max-width: 768px) {
  .Button {
    padding: 0 1rem;

    p {
      font-size: 1.5rem !important;
    }
  }
}
