@import "../../assets/scss/variables";

.Menu {
  position: fixed;
  background-color: $grey-color;
  border-radius: 50%;
  z-index: 20;
  opacity: 1;
  right: 5.5rem;
  top: 3rem;
  width: 80px;
  height: 80px;
}

.Content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--window-height);
  z-index: 200;
  opacity: 1;
  padding-top: 3rem;
  // padding-bottom: 6rem;
  padding-bottom: 3rem;

  svg {
    color: white;
    fill: white;
  }
}

.Sections {
  display: flex;
  flex-flow: column nowrap;
  opacity: 1;

  a {
    text-decoration: none;
    color: $white-color;
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 2rem;

    &:hover {
      color: $primary-color;
    }
  }
}

.TopRight {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}

.Close {
  color: $white-color;
  cursor: pointer !important;
  width: 1.5rem;

  &:hover {
    color: $primary-color;
  }
}

.ContentContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.MenuSocialContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.Social {
  cursor: pointer;
  margin-bottom: 0.8rem;
  z-index: 15;

  svg {
    height: 2.5rem;
    width: 2.5rem;

    &:hover {
      fill: $primary-color;
    }
  }

  svg:first-child {
    margin-right: 1rem;
  }
}

@media (max-width: 768px) {
  .Close {
    margin-right: 2rem;
    margin-left: 3rem;
    margin-bottom: 0.1rem;
  }
}
