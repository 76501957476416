@import "../../assets/scss/variables";

.Navigation {
  width: 100%;
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: 3.5rem 5.5rem;
  z-index: 10;
  pointer-events: none;

  img {
    width: 80px;
    cursor: pointer;
  }

  a {
    pointer-events: all;
  }

  img {
    pointer-events: all;
  }
}

.MarginRight {
  margin-right: 5rem;
}

.HoverLogo {
  transition: transform 200ms ease;
  &:hover {
    transform: scale(1.125);
  }
}

@media (max-width: 768px) {
  .Navigation {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    border-bottom: 2px solid $primary-color;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);

    img {
      width: 40px;
    }
  }

  .MarginRight {
    margin-right: 0;
  }
}
