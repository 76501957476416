.techPattern {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none !important;

  img {
    position: absolute;
  }

  img:nth-child(1) {
    width: 52%;
  }

  img:nth-child(2) {
    width: 56%;
  }

  img:nth-child(3) {
    width: 60%;
  }

  .bottomRight:nth-child(1) {
    bottom: -20px;
    right: -10px;
  }

  .bottomRight:nth-child(2) {
    bottom: -30px;
    right: -10px;
  }

  .bottomRight:nth-child(3) {
    bottom: 20px;
    right: -175px;
  }

  .topLeft:nth-child(1) {
    top: -20px;
    left: -10px;
    transform: rotate(180deg);
  }

  .topLeft:nth-child(2) {
    top: -30px;
    left: -10px;
    transform: rotate(180deg);
  }

  .topLeft:nth-child(3) {
    top: 20px;
    left: -175px;
    transform: rotate(180deg);
  }

  .half {
    opacity: 0.5;
  }
}

.smallTechPattern {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none !important;

  img {
    position: absolute;
    width: 23rem;
  }

  .left:nth-child(1) {
    top: 10%;
    left: -20%;
  }

  .left:nth-child(2) {
    top: -10%;
    left: -13%;
  }

  .right:nth-child(1) {
    top: 40%;
    right: -20%;
  }

  .right:nth-child(2) {
    top: 30%;
    right: -27%;
  }

  .leftCenter:nth-child(1) {
    top: 60%;
    transform: translateY(-40%);
    left: -5%;
  }

  .leftCenter:nth-child(2) {
    top: 50%;
    left: 0;
  }

  .farLeft:nth-child(1) {
    top: 25%;
    left: -30%;
  }

  .farLeft:nth-child(2) {
    top: 0%;
    left: -23%;
  }
}

.staticTechPattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img:nth-child(1) {
    position: absolute;
    transform: rotate(180deg);
    top: -30px;
    width: 100%;
    opacity: 0.8;
  }

  img:nth-child(2) {
    transform: rotate(180deg);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  // img:nth-child(3) {
  //   width: 60%;
  // }
}
