@import "../../assets/scss/variables";

.Form {
  width: 100%;
  font-size: 2.8rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill::first-line {
    box-shadow: 0 0 0 100px white inset !important;
    -webkit-box-shadow: 0 0 0 100px white inset !important;
    font-size: 2.8rem !important;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 2px solid $grey-color;
    padding: 1rem 0;
    font-size: 2.8rem;
    outline: none;
    background-color: transparent !important;
    -webkit-tap-highlight-color: transparent !important;

    &:focus {
      border-color: $primary-color;
    }

    &::placeholder {
      font-size: 2.8rem;
    }

    -webkit-autofill::first-line {
      font-size: 2.8rem !important;
    }
  }

  input[type="text"] {
    cursor: pointer;
  }
}

.InputContainer {
  margin-bottom: 4rem;

  p {
    font-size: 1.5rem;
    color: red;
    margin-top: 1rem;
  }

  img {
    width: 20px;
    cursor: pointer;
  }
}

.FormError {
  font-size: 1.8rem !important;
  color: red;
  margin-top: 1rem;
}

.Success {
  font-size: 1.8rem !important;
  margin-top: 1rem;
  color: #198754;
}
