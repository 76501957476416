@import "../../../assets/scss/variables";

.LanguageSelector {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 4.5rem;
  opacity: 1;
  pointer-events: all;
  // transition: all 10ms ease !important;

  p {
    color: $white-color;
    margin-bottom: 0;
    font-size: 1.4rem;
    padding: 0 0.6rem;

    &:hover {
      // color: $primary-color;
      cursor: pointer;
    }
  }

  span {
    display: block;
    height: 16px;
    border-left: 2px solid $white-color;
  }
}

.Active {
  font-weight: bold;
}

.LightTheme {
  p {
    color: $primary-color;
  }

  span {
    border-color: $primary-color;
  }
}

.DarkTheme {
  p {
    color: $white-color;
  }

  span {
    border-color: $white-color;
  }
}

.Hide {
  // visibility: hidden;
  opacity: 0 !important;
  pointer-events: none !important;
}

@media (max-width: 768px) {
  .LanguageSelector {
    margin-right: 0;

    p {
      font-size: 1.5rem;

      &:nth-child(1) {
        padding-left: 0 !important;
      }
    }

    span {
      height: 15px;
    }
  }
}
