@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Nunito+Sans:wght@400;600;700&display=swap");
@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
input {
  font-family: "Montserrat", sans-serif !important;
  opacity: 1;
  // font-size: 62px;
}

h2 {
  a {
    text-decoration: underline;
    color: inherit;

    &:hover,
    &:active {
      color: $primary-color;
    }
  }
}

body,
#root {
  width: 100vw !important;
  width: var(--window-width) !important;
  overflow-x: hidden !important;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.nunito-font {
  font-family: "Nunito Sans", sans-serif !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

// Bootstrap override

$theme-colors: (
  "primary": $primary-color,
  "light": $white-color,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
);

$h1-font-size: 4.4rem;

#contact {
  h4 {
    font-size: 2.2rem;
  }
}

.r3-font {
  font-size: 3rem !important;
}

.r37-font {
  font-size: 3.7rem !important;
}

.cmr-5 {
  margin-right: 5rem;
}

@media (min-width: 1300px) and (max-width: 1550px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1100px !important;
  }

  html {
    font-size: 75%;
  }
}

@media (min-width: 1100px) and (max-width: 1299.98px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1000px !important;
  }

  html {
    font-size: 65%;
  }
}

@media (min-width: 769px) and (max-width: 1099.98px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 768.98px) {
  .container,
  .container-fluid {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 2rem;
  }

  html {
    font-size: 50% !important;
  }

  #searches {
    padding-top: 15rem;
  }
}

@import "~bootstrap/scss/bootstrap.scss";
