@import "../../assets/scss/variables";
@mixin section {
  width: 100%;
  position: relative;
}

.DarkBg {
  background-color: $not-black-color !important;
}

.SectionFullHeight {
  @include section;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: var(--window-height);
  // height: 100vh;
  // height: var(--window-height);
  transition: height 100ms ease;
}

.Section {
  @include section;

  padding: 6rem 0;

  h1 {
    font-size: 4rem;
    color: $primary-color;
    font-weight: bold;
    margin-bottom: 5rem;
  }

  h2 {
    font-size: 3.7rem;
    color: black;
    font-weight: normal;
    margin-bottom: 8rem;
  }

  h3 {
    font-size: 3rem;
    color: $grey-color;
    font-weight: normal;
    margin-bottom: 8rem;
  }

  h4 {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 2.2rem;
  }

  p {
    font-size: 1.2rem;
  }

  :global(.organization-img) {
    display: block;
    margin: auto;
    height: 120px;
    text-align: center;
  }

  :global(.linkedin-svg) {
    height: 2.5rem;
    width: auto;
    fill: white;

    &:hover {
      fill: $primary-color;
    }
  }
}

.IntroSection {
  position: relative !important;
  // top: 0;
  // left: 0;
  z-index: 20;
  overflow: hidden !important;

  svg {
    cursor: default;
  }
}

.BottomLeftLogo {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  width: 22rem;
}

@media (max-width: 768px) {
  .Section {
    aside {
      flex-flow: column !important;
      align-items: start !important;
    }
  }
}
