.HoverAlmaTitles {
  margin-top: 8rem !important;
  margin-bottom: 16rem !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :global(.col) {
    &:nth-child(2) {
      padding: 3rem;
      margin-top: -160px;
    }
  }

  img {
    width: 80%;
    margin: auto;
    display: block;
  }
}

@media (max-width: 768px) {
  .HoverAlmaTitles {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
