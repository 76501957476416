@import "../../../assets//scss/variables";

.HoverTitle {
  position: relative;
  // overflow: hidden;
  h2 {
    text-align: center;
    width: 100%;
    color: $primary-color;
    font-weight: 700;
    border-top: 3px solid $primary-color;
    border-bottom: 3px solid $primary-color;
    font-size: 2.8rem;
    margin-bottom: 1rem;
    cursor: pointer;
    z-index: 2;
    background-color: white;
    position: relative;
  }

  p {
    margin-left: 1rem;
    font-size: 1.2rem;
    color: $grey-color;
    z-index: 1;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 4.5rem;
  }
}

@media (max-width: 768px) {
  .HoverTitle {
    margin-top: 3rem;
    margin-bottom: 3rem;
    // height: 180px;

    h2 {
      padding: 0.8rem 0;
    }

    p {
      position: relative;
      opacity: 1;
      font-size: 1.8rem;
      top: 0;
    }
  }
}
