.Gallery {
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: space-between;
  // align-items: center;
  width: 84%;
  margin: auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
  overflow: hidden;

  article {
    width: 49%;

    // &:nth-child(2) {
    //   transform: translateY(10%);
    // }
  }
}

@media (max-width: 768px) {
  .Gallery {
    article {
      width: 100%;

      // &:nth-child(2) {
      //   transform: initial;
      // }
    }
  }
}
