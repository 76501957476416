@import "../../../assets/scss/variables";

.Anchor {
  text-decoration: none;
  color: white;
}

.HexaLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.5rem;
  height: 14rem;
  transition: all 200ms ease;

  svg {
    height: 100%;
    width: 12.5rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  img {
    z-index: 1;
    width: 5rem;
    position: absolute;
    right: -30px;
    bottom: 5px;
    transition: all 300ms ease;
  }

  p {
    color: white;
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-bottom: 0;
    z-index: 5;
  }

  &:hover {
    transform: scale(1.15);

    img {
      transform: rotate(90deg);
    }
  }
}

.Button {
  border: none;
  background-color: transparent;
  outline: none;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.Loading {
  transform: scale(1.15);
  img {
    animation: loading 2000ms infinite linear;
  }
}
