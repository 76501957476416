.Arrow {
  position: fixed;
  right: 3.2rem;
  bottom: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100px);
  transition: all 200ms ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;

  img {
    width: 1.5rem;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }
}

.Show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .Arrow {
    right: 5rem;
    bottom: 5rem;
    width: 5rem;
    height: 5rem;

    img {
      width: 2rem;
    }
  }
}
