@import "../../../assets/scss/variables";

.FlipCardContainer {
  width: 100%;
  height: 53rem;
  perspective: 1500px;
  justify-self: center;
  margin-bottom: 1rem;
}

.FlipCard {
  width: inherit;
  height: inherit;

  position: relative;
  transform-style: preserve-3d;
  transition: 0.6s 0.1s;
  cursor: pointer;
}

.FlipCardContainer:hover .FlipCard,
.FlipCardContainer:focus-within .FlipCard {
  transform: rotateY(180deg);
}

.CardFront,
.CardBack {
  position: relative;
  width: 100%;
  height: 100%;

  background: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CardFront {
  transform: rotateY(0deg);
  z-index: 2;
}

.CardBack {
  transform: rotateY(180deg);
  z-index: 1;
  background-color: $not-black-color;

  .BackContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column nowrap;

    h4 {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.5rem;
      color: white;
      margin-bottom: 0;
      font-weight: 700;
    }

    h3 {
      font-size: 1.3rem;
      color: white;
      font-weight: 500;
      margin-bottom: 0;
      font-family: "Nunito Sans", sans-serif;
      margin-bottom: 2rem;
    }

    p {
      color: white;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
}

.CardFront img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
}

.BottomTitle {
  display: inline-block;
  color: black;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.2rem !important;
  padding-left: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;

  span {
    color: $grey2-color;
    font-weight: 400;
    margin-left: 0.5rem;
  }
}

.OrganizationFlipCard {
  height: 17.5rem !important;

  .CardFront {
    img {
      display: block;
      height: 7.5rem;
      width: auto;
      margin: auto !important;
      position: relative;
      // width: 100%;
      // object-fit: contain;
    }
  }

  .CardBack {
    background: transparent;

    p {
      padding: 0 4rem;
      font-size: 1.3rem;
      margin-bottom: 0;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

.OrganizationMobileCard {
  margin: 4rem 0;

  img {
    display: block;
    height: 10rem;
    margin: 0 auto 2rem auto;
  }

  p {
    width: 90%;
    margin: auto;
    font-size: 1.8rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .BottomTitle {
    font-size: 1.6rem !important;
  }

  .BackContainer {
    p {
      font-size: 1.4rem !important;
    }
  }

  .FlipCardContainer {
    height: 62rem;
  }

  .OrganizationFlipCard {
    height: 30rem !important;

    .CardFront {
      img {
        height: 8.5rem;
      }
    }

    .CardBack {
      p {
        padding: 0 2rem;
        font-size: 1.6rem;
      }

      svg {
        width: auto;
      }
    }
  }
}
